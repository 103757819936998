html, body {
  text-align: center;
  margin: 0;
  padding: 0;
  background:rgb(83, 82, 82);
  overflow: auto;
}

#root {
  overflow: auto;
  overflow-y: scroll; /* Show scrollbars */
}

.top-spacer {
  height:1%;
}

.header {
  text-align: left;
  padding-top:15px;
  margin-left:20px;
  margin-right:10px;
}


.header {

  display: grid;
  grid-template-columns: 60% auto ;
  margin-right: 20px;
  /* grid-template-columns: 10% auto 10% ; */
}

.slogan-holder {
  position: relative;
  text-align: center;
  margin-left: 10px;
}

/* .slogan2 {

  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: white;
  font-size: +2em;
  font-weight: bold;
  font-style: italic;

  display: inline-block;
  width: 95%;
  max-width: 800px;

  text-align: center;
} */

.slogan {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: white;
  /* font-size: calc(16px + 13 * ((100vw - 320px) / 680)); */
  font-weight: bold;
  font-style: italic;
  display: inline-block;
  width: 100%;
    
  /* position: absolute;
  bottom: 0;
  left: 0; */

  /* Horizonal & Vertical Center
      https://www.w3schools.com/howto/howto_css_center-vertical.asp*/
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media only screen and (min-device-width : 0px) and (max-device-width : 480px) {

  .slogan {
    
    font-size: 12pt;
  }
}
@media only screen and (min-width : 481px) and (max-width : 900px){
  .slogan {
    font-size: 18pt;
  }
}

@media only screen and (min-width : 900px) {
  .slogan {
    font-size: 24pt;
  }
}

.markdown {
  text-align: left;
}

.underline-on-hover {
  text-decoration: underline;
}

a {
  color:rgb(0 142 206);
}

.panel-footer {
  border-top:1px solid grey;
  padding-top:20px;
  margin-top:40px;
}


/*   HOMEPAGE STUFF */

.links-holder{

  display: inline-block;
  text-align: center;
  position:relative;
}

.links{
  display: inline-block;
  text-align: center;
  width:100%;

}


.store-badge {
  display: inline-block;
  text-align: center;
}

.store-badge img {
  margin-top: 40px;
  vertical-align: middle;
  width:95%;
}

.phone-a{
  margin-top:50px;
  /* height:55vw;         */
  width: 30vh;
  max-width: 100%;
  margin-left:30px;
  margin-bottom:50px;
  transform: rotate(15deg);
}


.home-panel {
  display: grid;
  grid-template-columns: 40% 60%;
  max-height: 1024px;
  min-height: 440px;
  margin-left: 20px;
  margin-right: 20px;
}


.small-print-footer {
  text-decoration: none;
  /* position: absolute;
  vertical-align: text-bottom;
  margin-top: 10vh;
  margin-bottom: 20px;
  bottom: 2px;
  left:0px;
  right:0px; */
  font-size: max(1vw, 9pt);
}


.footer {
      /* background: white; */
      max-width: 300px;
      display: inline-block;
      padding: 10px;
      /* opacity: .3; */
      border-radius: 10px;
}
.footer a{
  color:rgb(186 186 186);
}

.hook {

  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: white;
  font-size:24pt;
  /* font-size: calc(16px + 13 * ((100vw - 320px) / 680)); */
  font-weight: bold;
  font-style: italic;
  display: inline-block;
  width: 100%;

}



@media only screen and  (min-width : 682px){

  .links{
  
    /* Horizonal & Vertical Center
       https://www.w3schools.com/howto/howto_css_center-vertical.asp*/
    margin: 0;
    position: absolute;
    top: 40%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
@media only screen and  (max-width : 681px){
  .home-panel {
    display: grid;
    grid-template-columns: auto;
  }
  .phone-a{ 
    width: 25vh;
  }
  .store-badge img {
    width:75%;
  }

  .hook {
    /* text-align: left; */
    margin-top: 30px;
    font-size: +2em;
  }

}

.marketing-assets h1 {
  text-align:left;
}
.marketing-asset .marketing-material-preview img {
  border:1px solid rgb(178, 178, 178);
  width:300px;
}

.marketing-asset .marketing-material-preview img:hover {

  border:1px solid rgb(147, 147, 147);
}

.marketing-asset .marketing-material-preview  {
  margin-top:20px;
}

.marketing-asset {
  padding:20px;
  display: grid;
  grid-template-columns: 50% 50% ;
}

.invite-screen {
  font-family: Helvetica, Verdana, Geneva, Tahoma, sans-serif;
}

.invite-screen .title {
  font-family: Helvetica, Verdana, Geneva, Tahoma, sans-serif;
  font-size: 35pt;
  margin-top: 10px;
}
.invite-screen .description {
  text-align: left;
  white-space: pre-line;
  font-weight: normal;
}

.invite-screen .infos-list {
  text-align: left;
}


.event-showcase-summary, .title, .event-showcase-summary, .name, .event-showcase-summary, .description{
  text-align: left;
}

.event-showcase-summary .public-code-logo {
  width: 128px;
  height: 128px;
  margin-right:20px;
}


.event-showcase-summary .title {
  margin-top:0px;
  margin-bottom:0px;
}
.event-showcase-summary .sub-title {
  margin-top:5px;
  margin-bottom:0px;
}

.event-showcase-summary .description {
  margin-right:30px;
  white-space: pre-line;
}


.event-showcase-event-series {
  display: grid ;
  grid-template-columns: 150px 1fr ;
}
.event-showcase-event-series .event-logo {
  width: 96px;
  height: 96px;
}

.event-showcase-event-series .event-breakdown {
  text-align: left;
}

.event-series-header {
  display: grid ;
  grid-template-columns: auto auto ;
  text-align: left;
}

.event-series-header .event-series-price {
  text-align:right;
  font-size:1.5em;
  font-weight: lighter;
}

.event-series-header .event-series-name {
  font-size: 1.5em;
}

.event-showcase, .event-showcase .panel, .event-showcase h1, .event-showcase h2, .event-showcase h3, .event-showcase h4 {
  font-family: Helvetica, Verdana, Geneva, Tahoma, sans-serif;
}

.event-showcase h1 {
  font-size: 35pt;
  font-weight: 400;
}



@media only screen and (min-device-width : 0px) and (max-device-width : 480px) {

  .event-showcase-summary{
    width:100%;
    display: block ;
    text-align: center;
  }


  .event-showcase-summary .public-code-logo {
    width: 50px;
    height: 50px;
  }

  .event-showcase h1 {
    font-size: 25pt;
    font-weight: 400;
    text-align: center;
    margin:0px
  }

}

@media only screen and (min-width : 481px){
 
  .event-showcase-summary{
    margin:10px;
    width:100%;
    display: grid ;
    grid-template-columns: 150px 1fr ;
  }
  .event-showcase-summary .public-code-overview {
    margin-left:10px;
  }
}



.event-series-details-screen .description{
  text-align: left;
  white-space: pre-line;
}

.event-showcase-date-table-entry {
  font-size: 1.4em;
  font-weight: bold;
}







@media only screen and (min-device-width : 0px) and (max-device-width : 480px) {

  .invite-summary .public-code-logo {
    width: 48px;
    height: 48px;
  }
  
  .invite-summary{
    width:100%;
    display: block ;
    text-align: center;
  }

  .invite-summary .title {
    text-align: center;
  }

  .invite-summary .public-code-logo {
    width: 50px;
    height: 50px;
  }

}

@media only screen and (min-width : 481px){
 
  .invite-summary .public-code-logo {
    width: 128px;
    height: 128px;
    margin-right:20px;
  }
  .invite-summary{
    margin:10px;
    width:100%;
    display: grid ;
    grid-template-columns: 150px 1fr ;
  }
  .invite-summary .public-code-overview {
    margin-left:10px;
  }
}


.venmo-button {
  padding:15px;
  border-radius:5px;
  background:rgb(0, 140, 255);
}
.venmo-button:hover {
  background:rgb(10, 121, 211);
  cursor: pointer;
}

.generic-payment-button {
  display: inline-block;
  color:white;
  font-size: 1.6em;
  font-weight: bold;
  max-width: 500px;
  padding:15px;
  border-radius:5px;
  background:rgb(0, 140, 255);
  margin:15px;
}
.generic-payment-button:hover {
  background:rgb(10, 121, 211);
  cursor: pointer;
}